<script>
import request from '../../../../../utils/request';
import Form, { formCreate } from '../../../../../components/form';
import BasicInfo from './components/basic_info.vue';

formCreate.component('sfaAttendanceLeaveApplyDetailBasicInfo', BasicInfo);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    id: String,
  },
  data() {
    return {
      buttons: {
        submit: false,
        close: false,
      },
    };
  },
  methods: {
    getDetail(id) {
      request.post('/sfa/sfaSignFormsController/queryleave', id).then((res) => {
        if (!res.success) return;
        this.setValue({
          basicInfo: { ...res.result },
        });
      });
    },
  },
  async created() {
    /** @desc 增加菜单编码,供流程中心，单据(查看详情时)使用 */
    await this.getFormRule('sfa_attendance_leave_apply_details_check', 'CRM20201128000000132');
    /**
     * @todo this.id 是通过流程中心来查的
     * @todo this.formConfig.row.auditTaskId是本身列表页查询的id
    */
    console.log('请假申请明细===========props.id', this.id);
    console.log('请假申请明细===========formConfig.row', this.formConfig.row);
    if (this.id) {
      this.getDetail(this.id);
    } else if (this.formConfig.row.auditTaskId) {
      this.getDetail(this.formConfig.row.auditTaskId);
    }
  },
};
</script>
