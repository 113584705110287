var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "150px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请人" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.realName,
                      callback: function ($$v) {
                        _vm.realName = $$v
                      },
                      expression: "realName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请人职位名称" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.posName,
                      callback: function ($$v) {
                        _vm.posName = $$v
                      },
                      expression: "posName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请人所属组织" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.orgName,
                      callback: function ($$v) {
                        _vm.orgName = $$v
                      },
                      expression: "orgName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请日期" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.applicationDate,
                      callback: function ($$v) {
                        _vm.applicationDate = $$v
                      },
                      expression: "applicationDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请假类型" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.leaveTypeName,
                      callback: function ($$v) {
                        _vm.leaveTypeName = $$v
                      },
                      expression: "leaveTypeName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "开始时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.beginTime,
                      callback: function ($$v) {
                        _vm.beginTime = $$v
                      },
                      expression: "beginTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "结束时间" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.endTime,
                      callback: function ($$v) {
                        _vm.endTime = $$v
                      },
                      expression: "endTime",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请时长(天)" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.leaveDuration,
                      callback: function ($$v) {
                        _vm.leaveDuration = $$v
                      },
                      expression: "leaveDuration",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "审批状态" } },
                [
                  _c("el-input", {
                    attrs: { readonly: "" },
                    model: {
                      value: _vm.bpmStatusName,
                      callback: function ($$v) {
                        _vm.bpmStatusName = $$v
                      },
                      expression: "bpmStatusName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "请假事由" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      resize: "none",
                      rows: 5,
                      readonly: "",
                    },
                    model: {
                      value: _vm.leaveReason,
                      callback: function ($$v) {
                        _vm.leaveReason = $$v
                      },
                      expression: "leaveReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申请日期" } },
                [
                  _c(
                    "vxe-table",
                    { attrs: { data: _vm.timeInfoListJson } },
                    [
                      _c("vxe-table-column", {
                        attrs: { field: "timeStr", title: "日期" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { field: "timeTypeName", title: "类型" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "销假明细" } },
                [
                  _c(
                    "vxe-table",
                    { attrs: { data: _vm.leaveCancelRespVoList } },
                    [
                      _c("vxe-table-column", {
                        attrs: { title: "销假开始时间", field: "beginTime" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "销假结束时间", field: "endTime" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "销假明细", field: "timeDetail" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "销假天数（天）", field: "cancelDays" },
                      }),
                      _c("vxe-table-column", {
                        attrs: { title: "审批状态", field: "bpmStatusName" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { staticClass: "img-url", attrs: { span: 24 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "附件" } },
                _vm._l(_vm.attachmentListJson, function (item, index) {
                  return _c("el-image", {
                    key: index,
                    attrs: {
                      "preview-src-list": [item.picUrl + item.picPath],
                      src: item.picUrl + item.picPath,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }