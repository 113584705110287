<template>
  <el-form label-width="150px">
    <el-row>
      <el-col :span="12">
        <el-form-item label="申请人">
          <el-input v-model="realName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请人职位名称">
          <el-input v-model="posName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请人所属组织">
          <el-input v-model="orgName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请日期">
          <el-input v-model="applicationDate" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="请假类型">
          <el-input v-model="leaveTypeName" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="开始时间">
          <el-input v-model="beginTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="结束时间">
          <el-input v-model="endTime" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="申请时长(天)">
          <el-input v-model="leaveDuration" readonly></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="审批状态">
          <el-input v-model="bpmStatusName" readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="请假事由">
          <el-input v-model="leaveReason" type="textarea" resize='none' :rows="5"  readonly></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="申请日期">
          <vxe-table :data="timeInfoListJson">
            <vxe-table-column field="timeStr" title="日期"></vxe-table-column>
            <vxe-table-column field="timeTypeName" title="类型"></vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="销假明细">
          <vxe-table :data="leaveCancelRespVoList">
            <!-- <vxe-table-column title="日期明细" type="expand">
              <template v-slot:content="{row}">
                <vxe-table :data="row.timeInfoList">
                  <vxe-table-column title="日期" field="timeStr"></vxe-table-column>
                  <vxe-table-column title="日期类型" field="timeTypeName"></vxe-table-column>
                </vxe-table>
              </template>
            </vxe-table-column> -->
            <vxe-table-column title="销假开始时间" field="beginTime"></vxe-table-column>
            <vxe-table-column title="销假结束时间" field="endTime"></vxe-table-column>
            <vxe-table-column title="销假明细" field="timeDetail"></vxe-table-column>
            <vxe-table-column title="销假天数（天）" field="cancelDays"></vxe-table-column>
            <vxe-table-column title="审批状态" field="bpmStatusName"></vxe-table-column>
          </vxe-table>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="img-url">
        <el-form-item label="附件">
          <el-image v-for="(item,index) in attachmentListJson" :preview-src-list="[item.picUrl+item.picPath]" :src="item.picUrl+item.picPath" :key="index"></el-image>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
const enumTimeType = {
  1: '全天',
  2: '上午',
  3: '下午',
};
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {
          realName: '', // 申请人
          posName: '', // 申请人职位名称
          orgName: '', // 申请人所属组织
          applicationDate: '', // 申请日期
          leaveTypeName: '', // 请假类型
          beginTime: '', // 开始时间
          endTime: '', // 结束时间
          leaveDuration: '', // 申请时长
          bpmStatusName: '', // 审批状态
          leaveReason: '', // 请假事由
          leaveCancelRespVoList: [], // 销假明细
          attachmentListJson: [], // 附件
        };
      },
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.realName = val.realName;
          this.posName = val.posName;
          this.orgName = val.orgName;
          this.applicationDate = val.applicationDate;
          this.leaveTypeName = val.leaveTypeName;
          this.beginTime = val.beginTime;
          this.endTime = val.endTime;
          this.leaveDuration = val.leaveDuration;
          this.bpmStatusName = val.bpmStatusName;
          this.leaveReason = val.leaveReason;
          this.attachmentListJson = val.attachmentListJson ? JSON.parse(val.attachmentListJson) : [];
          this.timeInfoListJson = val.timeInfoListJson ? JSON.parse(val.timeInfoListJson).map((item) => ({ ...item, timeTypeName: enumTimeType[item.timeType] })) : [];
          /** @todo 这是一个双循环 */
          this.leaveCancelRespVoList = val.leaveCancelRespVoList ? val.leaveCancelRespVoList.map((item) => {
            const row = { ...item };
            if (row.timeInfoList && Array.isArray(row.timeInfoList)) {
              // row.timeInfoList = row.timeInfoList.map((mitem) => ({ ...mitem, timeTypeName: enumTimeType[mitem.timeType] }));
              row.timeDetail = '';
              row.timeInfoList.map((mitem) => {
                row.timeDetail += `${mitem.timeStr}：${enumTimeType[mitem.timeType]}\n`;
                return row.timeDetail;
              });
            }
            return row;
          }) : [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      realName: '', // 申请人
      posName: '', // 申请人职位名称
      orgName: '', // 申请人所属组织
      applicationDate: '', // 申请日期
      leaveTypeName: '', // 请假类型
      beginTime: '', // 开始时间
      endTime: '', // 结束时间
      leaveDuration: '', // 申请时长
      bpmStatusName: '', // 审批状态
      leaveReason: '', // 请假事由
      attachmentListJson: [], // 附件
      leaveCancelRespVoList: [], // 销假明细
      timeInfoListJson: [], // 日期
    };
  },
};
</script>

<style lang="less" scoped>
  /deep/.el-image__inner ,
  /deep/ .el-image__error{
    width: 120px;
    height: 120px;
    margin-right: 6px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
    outline: 0;
    object-fit: contain;
  }
  /deep/ .img-url .el-form-item__content > div{
    width: auto;
  }
</style>
